import './App.css';
import socketIOClientNew from "socket.io-client";
import socketIOClientOld from "socket.io-client-old";
import { useState, useEffect } from "react"
import ItemCard from './components/ItemCard';

const urlProd = 'https://aos-socket.celemilearningspace.com/';
const urlStaging = 'https://aos-socket.staging.celemilearningspace.com/';
const urlBackendProd = 'https://backend.celemilearningspace.com/';
const urlBackendStaging = 'https://backend.staging.celemilearningspace.com/';

function App() {

    const [isProd, setIsProd] = useState(true);
    const [isOldSocket, setIsOldSocket] = useState(true);
    const [isPolling, setIsPolling] = useState(false);

    const [reachBackendProdEnabled, setReachBackendProdEnabled] = useState(false);
    const [reachBackendProdLoading, setReachBackendProdLoading] = useState(false);
    const [reachBackendProd, setReachBackendProd] = useState(false);

    const [reachBackendStagingEnabled, setReachBackendStagingEnabled] = useState(false);
    const [reachBackendStagingLoading, setReachBackendStagingLoading] = useState(false);
    const [reachBackendStaging, setReachBackendStaging] = useState(false);

    const [reachSocketProdEnabled, setReachSocketProdEnabled] = useState(false);
    const [reachSocketProdLoading, setReachSocketProdLoading] = useState(false);
    const [reachSocketProd, setReachSocketProd] = useState(false);

    const [reachSocketStagingEnabled, setReachSocketStagingEnabled] = useState(false);
    const [reachSocketStagingLoading, setReachSocketStagingLoading] = useState(false);
    const [reachSocketStaging, setReachSocketStaging] = useState(false);

    const [socketPollingProdEnabled, setSocketPollingProdEnabled] = useState(false);
    const [socketPollingProdLoading, setSocketPollingProdLoading] = useState(false);
    const [socketPollingProd, setSocketPollingProd] = useState(false);

    const [socketPollingStagingEnabled, setSocketPollingStagingEnabled] = useState(false);
    const [socketPollingStagingLoading, setSocketPollingStagingLoading] = useState(false);
    const [socketPollingStaging, setSocketPollingStaging] = useState(false);

    const [socketWebSocketProdEnabled, setSocketWebSocketProdEnabled] = useState(false);
    const [socketWebSocketProdLoading, setSocketWebSocketProdLoading] = useState(false);
    const [socketWebSocketProd, setSocketWebSocketProd] = useState(false);

    const [socketWebSocketStagingEnabled, setSocketWebSocketStagingEnabled] = useState(false);
    const [socketWebSocketStagingLoading, setSocketWebSocketStagingLoading] = useState(false);
    const [socketWebSocketStaging, setSocketWebSocketStaging] = useState(false);

    const setReachBackend = (val) => isProd ?  setReachBackendProd(val) : setReachBackendStaging(val);
    const setReachBackendEnabled = (val) => isProd ?  setReachBackendProdEnabled(val) : setReachBackendStagingEnabled(val);
    const setReachBackendLoading = (val) => isProd ?  setReachBackendProdLoading(val) : setReachBackendStagingLoading(val);
    
    const setReachSocket = (val) => isProd ?  setReachSocketProd(val) : setReachSocketStaging(val);
    const setReachSocketEnabled = (val) => isProd ?  setReachSocketProdEnabled(val) : setReachSocketStagingEnabled(val);
    const setReachSocketLoading = (val) => isProd ?  setReachSocketProdLoading(val) : setReachSocketStagingLoading(val);
    
    const setSocketPolling = (val) => isProd ?  setSocketPollingProd(val) : setSocketPollingStaging(val);
    const setSocketPollingEnabled = (val) => isProd ?  setSocketPollingProdEnabled(val) : setSocketPollingStagingEnabled(val);
    const setSocketPollingLoading = (val) => isProd ?  setSocketPollingProdLoading(val) : setSocketPollingStagingLoading(val);
    
    const setSocketWebSocket = (val) => isProd ?  setSocketWebSocketProd(val) : setSocketWebSocketStaging(val);
    const setSocketWebSocketEnabled = (val) => isProd ?  setSocketWebSocketProdEnabled(val) : setSocketWebSocketStagingEnabled(val);
    const setSocketWebSocketLoading = (val) => isProd ?  setSocketWebSocketProdLoading(val) : setSocketWebSocketStagingLoading(val);

    const [testSocketFlag, setTestSocketFlag] = useState(false);

    const reachBackend = async() => {
        setReachBackendEnabled(true);
        setReachBackendLoading(true);
        console.log('Testing backend endpoint - ' + (isProd ? 'production' : 'staging'));
        try {
            const response = await fetch((isProd ? urlBackendProd : urlBackendStaging) + 'features/defaultUser')
            if (response.ok) {
                const data = await response.json();
                if(data.data) {
                    setReachBackend(true);
                }
            }
        } catch(error) {};

        setReachBackendLoading(false);
    } 

    const reachSocket = async() => {
        setReachSocketEnabled(true);
        setReachSocketLoading(true);
        console.log('Testing socket endpoint - ' + (isProd ? 'production' : 'staging'));
        try {
            const response = await fetch((isProd ? urlProd : urlStaging) + 'health_check')
            if (response.ok) {
                const data = await response.text();
                if(data == 'OK') {
                    setReachSocket(true);
                }
            }
        } catch(error) {};

        setReachSocketLoading(false);
    } 

    const testSocket = async() => {
        console.log('Testing Web' + (isPolling ? 'Polling' : 'Socket') + ' connection - ' + (isProd ? 'production' : 'staging'));
        setTestSocketFlag(false);
        isPolling ? setSocketPollingEnabled(true) : setSocketWebSocketEnabled(true);
        isPolling ? setSocketPollingLoading(true) : setSocketWebSocketLoading(true);
        const socketIOClient = isOldSocket ? socketIOClientOld : socketIOClientNew;
        const maxTime = setTimeout(() => {
            socketCheckingFinished();
        }, 10000);

        try {

            const socket = socketIOClient(isProd ? urlProd : urlStaging, {
                upgrade: false,
                transports: isPolling ? ["polling"] : ["websocket"],
            });
    
            socket.on("connect", () => {
                isPolling ? setSocketPolling(true) : setSocketWebSocket(true);
                clearTimeout(maxTime);
                socketCheckingFinished();
            });
    
            socket.io.on("error", (e) => {
                clearTimeout(maxTime);
                socketCheckingFinished();
            });
    
            socket.on("connect_error", (e) => {
                clearTimeout(maxTime);
                socketCheckingFinished();
            });
        } catch (e) {
            clearTimeout(maxTime);
            socketCheckingFinished();
        }
    }

    const socketCheckingFinished = () => {
        isPolling ? setSocketPollingLoading(false) : setSocketWebSocketLoading(false);
    }

    const switchToStaging = () => {
        if(isProd) {
            setIsProd(false);
        }
    }

    useEffect(() => {
        reachBackend();
    }, []);

    useEffect(() => {
        if(!isProd) {
            reachBackend();
        }
    }, [isProd]);
    

    useEffect(() => {
        if((reachBackendProdEnabled && !reachBackendProdLoading && !reachBackendStagingEnabled) || (reachBackendStagingEnabled && !reachBackendStagingLoading)) {
            reachSocket();
        }
    }, [reachBackendProdLoading, reachBackendStagingLoading]);

    useEffect(() => {
        if((reachSocketProdEnabled && !reachSocketProdLoading && !reachSocketStagingEnabled) || (reachSocketStagingEnabled && !reachSocketStagingLoading)) {
            testSocket();
        }
    }, [reachSocketProdLoading, reachSocketStagingLoading]);


    useEffect(() => {
        if(testSocketFlag) {
            testSocket();
        }
    }, [isPolling]);

    useEffect(() => {
        if((socketWebSocketProdEnabled && !socketWebSocketProdLoading && !socketWebSocketStagingEnabled) || (socketWebSocketStagingEnabled && !socketWebSocketStagingLoading)) {
            setTestSocketFlag(true);
            setIsPolling(true);
        }
    }, [socketWebSocketProdEnabled, socketWebSocketProdLoading, socketWebSocketStagingEnabled, socketWebSocketStagingLoading]);

    useEffect(() => {
        if(socketPollingProdEnabled && !socketPollingProdLoading ) {
            setIsPolling(false);
            switchToStaging();
        }
    }, [socketPollingProdEnabled, socketPollingProdLoading]);
    
    
    return (
        <div className="App">
            <header className="App-header">
                <ItemCard 
                    name='Backend API'
                    enabled={reachBackendProdEnabled}
                    loading={reachBackendProdLoading}
                    successful={reachBackendProd}
                />    
                <ItemCard 
                    name='Socket endpoint API'
                    enabled={reachSocketProdEnabled}
                    loading={reachSocketProdLoading}
                    successful={reachSocketProd}
                />    
                <ItemCard 
                    name='WebSocket connection'
                    enabled={socketWebSocketProdEnabled}
                    loading={socketWebSocketProdLoading}
                    successful={socketWebSocketProd}
                />      
                <ItemCard 
                    name='WebPolling connection'
                    enabled={socketPollingProdEnabled}
                    loading={socketPollingProdLoading}
                    successful={socketPollingProd}
                />    
                <ItemCard 
                    name='Staging Backend API'
                    enabled={reachBackendStagingEnabled}
                    loading={reachBackendStagingLoading}
                    successful={reachBackendStaging}
                />    
                <ItemCard 
                    name='Staging Socket endpoint API'
                    enabled={reachSocketStagingEnabled}
                    loading={reachSocketStagingLoading}
                    successful={reachSocketStaging}
                />    
                <ItemCard 
                    name='Staging WebSocket connection'
                    enabled={socketWebSocketStagingEnabled}
                    loading={socketWebSocketStagingLoading}
                    successful={socketWebSocketStaging}
                />   
                <ItemCard 
                    name='Staging WebPolling connection'
                    enabled={socketPollingStagingEnabled}
                    loading={socketPollingStagingLoading}
                    successful={socketPollingStaging}
                />   
            
            </header>
        </div>
    );
}

export default App;
