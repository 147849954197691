import React, { useState, useEffect } from 'react'


const ItemCard = ({name, enabled, loading, successful}) => {

    const backgrounds = {
        bgDisabled: 'linear-gradient(to right, #cfd9df 0%, #e2ebf0 100%)',
        bgEnabled:  'linear-gradient(to right, #4facfe 0%, #00f2fe 100%)',
        bgChecked: 'linear-gradient(to right, #0ba360 0%, #3cba92 100%)',
        bgFailed:  'linear-gradient(to right, #ff0844 0%, #ffb199 100%)'
    }

const [isChecking, setIsChecking] = useState(false)
const [backgroundCss, setBackgroundCss] = useState(backgrounds.bgDisabled)

useEffect(() => {
    setIsChecking(loading);
    if (!enabled) {
        setBackgroundCss(backgrounds.bgDisabled);
    } else if (loading) {
        setBackgroundCss(backgrounds.bgEnabled);
    } else {
        if (successful) {
            setBackgroundCss(backgrounds.bgChecked);
        } else {
            setBackgroundCss(backgrounds.bgFailed);
        }
    }
}, [enabled, successful, loading])


return (
    <div 
    style={{backgroundImage : backgroundCss}}
    className='card text-start shadow mb-2 bg-body-tertiary rounded border border-0'>
        <div className="card-body d-flex justify-content-between items-center">
            <h5 className="card-title text-light mb-0">{name}</h5>
            {isChecking == true ? 
                <div className="spinner-grow" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                :
                null
            }
        </div>
    </div>
  )
}

export default ItemCard